import da from "../locales/da.json"
import de from "../locales/de.json"
import en from "../locales/en.json"
import no from "../locales/no.json"
import se from "../locales/se.json"
import pt from "../locales/pt.json"
export default defineI18nConfig(() => ({
  legacy: false,
  locale: "da_DK",
  fallbackLocale: "en_US",
  messages: {
    da_DK: da,
    en_US: en,
    de_DE: de,
    nb_NO: no,
    sv_SE: se,
    pt_PT: pt,
  },
}))
